export const userLoading = state => {
  return state.loading
}

export const userMenus = state => {
  return state.userMenus
}

export const allRoles = state => {
  return state.allRoles
}

export const roleMenus = state => {
  return state.roleMenus
}

export const allUsers = state => {
  return state.allUsers
}

export const userStatus = state => {
  return state.status
}

export const verifyStatus = state => {
  return state.verifyStatus
}


export const userNotes = state => {
  return state.userNotes
}
