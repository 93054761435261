import request from '@/helpers/request'

export const allCountries_list = async ({ commit }, data) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/allCountries', data)
  commit('allCountries', res.data)
  commit('comboLoading', false)
}

export const allCities_list = async ({ commit }, data) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/getAllCities', {params: data})
  commit('allCities', res.data)
  commit('comboLoading', false)
}

export const allStates_list = async ({ commit }, data) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/allStates', {params: data})
  commit('allStates', res.data)
  commit('comboLoading', false)
}

export const allDistrict_list = async ({ commit }, data) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/getAllDistricts', {params: data})
  commit('allDistrict', res.data)
  commit('comboLoading', false)
}

export const lang_list = async ({ commit }, data) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/langs', {params: data})
  commit('langs', res.data)
  commit('comboLoading', false)
}

export const contentType_list = async ({ commit }, data) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/contentTypes', {params: data})
  commit('contentTypes', res.data)
  commit('comboLoading', false)
}

export const contentGroup_list = async ({ commit }, data) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/contentGroups', {params: data})
  commit('contentGroups', res.data)
  commit('comboLoading', false)
}

export const activeProduct_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activeProducts')
  commit('activeProducts', res.data)
  commit('comboLoading', false)
}

export const activeProductType_list = async ({ commit }, data) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activeProductTypes', {params: data})
  commit('activeProductTypes', res.data)
  commit('comboLoading', false)
}

export const activePos_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activePOS')
  commit('activePos', res.data)
  commit('comboLoading', false)
}

export const activeInventoryMove_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activeInventoryMoves')
  commit('activeInventoryMoves', res.data)
  commit('comboLoading', false)
}

export const activeProductTypesWoS_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activeProductTypesWoS')
  commit('activeProductTypesWoS', res.data)
  commit('comboLoading', false)
}

export const activeProductGroups_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activeProductGroups')
  commit('activeProductGroups', res.data)
  commit('comboLoading', false)
}

export const activeDesigner_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activeDesigners')
  commit('activeDesigners', res.data)
  commit('comboLoading', false)
}

export const activeAdCamp_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activeAdCamps')
  commit('activeAdCamps', res.data)
  commit('comboLoading', false)
}

export const activeCurrency_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/activeCurrencies')
  commit('activeCurrencies', res.data)
  commit('comboLoading', false)
}

export const adminUser_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/adminUsers')
  commit('adminUsers', res.data)
  commit('comboLoading', false)
}

export const shippingCompany_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/allShippingCompanies')
  commit('shippingCompanyList', res.data)
  commit('comboLoading', false)
}

export const affiliateUser_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/affiliateUsers')
  commit('affiliateUserList', res.data)
  commit('comboLoading', false)
}

export const roleSecurityLevel_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/roleSecurityLevels')
  commit('roleSecurityLevels', res.data)
  commit('comboLoading', false)
}

export const careerPosition_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/careerPositions')
  commit('careerPositions', res.data)
  commit('comboLoading', false)
}

export const careerDepartments_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/careerDepartments')
  commit('careerDepartments', res.data)
  commit('comboLoading', false)
}

export const adcampType_list = async ({ commit }) => {
  commit('comboLoading', true)
  let res = await request('post', 'combo/adcampType')
  commit('adcampTypes', res.data)
  commit('comboLoading', false)
}
