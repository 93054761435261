
export const comboLoading = state => {
  return state.loading
}

export const allCountries = state => {
  return state.countries
}

export const allCities = state => {
  return state.cities
}

export const allStates = state => {
  return state.states
}

export const allDistrict = state => {
  return state.districts
}

export const langs = state => {
  return state.langs
}

export const contentTypes = state => {
  return state.contentTypes
}

export const contentGroups = state => {
  return state.contentGroups
}

export const activeProducts = state => {
  return state.activeProducts
}

export const activeProductTypes = state => {
  return state.activeProductTypes
}

export const activePos = state => {
  return state.activePos
}

export const activeInventoryMoves = state => {
  return state.activeInventoryMoves
}

export const activeProductTypesWoS = state => {
  return state.activeProductTypesWoS
}

export const activeProductGroups = state => {
  return state.activeProductGroups
}

export const activeDesigners = state => {
  return state.activeDesigners
}

export const activeCurrencies = state => {
  return state.activeCurrencies
}

export const adminUsers = state => {
  return state.adminUsers
}

export const shippingCompanyList = state => {
  return state.shippingCompanyList
}

export const activeAdCamps = state => {
  return state.activeAdCamps
}

export const affiliateUserList = state => {
  return state.affiliateUserList
}

export const roleSecurityLevels = state => {
  return state.roleSecurityLevels
}

export const careerPositions = state => {
  return state.careerPositions
}

export const careerDepartments = state => {
  return state.careerDepartments
}

export const adcampTypes = state => {
  return state.adcampTypes
}


