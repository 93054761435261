import { convertNullToString } from "@/helpers/custom"

export const inventoryLoading = (state, payload) => {
  state.loading = payload
}

export const inventoryStatus = (state, payload) => {
  state.status =  payload
}

export const moveLog = (state, payload) => {
  state.moveLog = payload.root ? convertNullToString(payload.root) : []
}

export const inventory = (state, payload) => {
  state.inventory = payload.root ? convertNullToString(payload.root) : []
}

export const inventorySummaries = (state, payload) => {
  state.inventorySummaries = payload.root ? convertNullToString(payload.root) : []
}

export const inventorySummaryDetail = (state, payload) => {
  state.inventorySummaryDetail = payload.root ? convertNullToString(payload.root) : []
  state.inventorySummaryDetailCount = payload.totalCount ? payload.totalCount : 0
}

export const inventoryLogs = (state, payload) => {
  state.inventoryLogs = payload.root ? convertNullToString(payload.root) : []
}

export const inventoryTrackings = (state, payload) => {
  state.inventoryTrackings = payload.root ? convertNullToString(payload.root) : []
}

export const logisticInventories = (state, payload) => {
  state.logisticInventories = payload.root ? convertNullToString(payload.root) : []
}

export const logisticInventoryTransfer = (state, payload) => {
  state.logisticInventoryTransfer = payload.root ? convertNullToString(payload.root) : []
}

export const logisticInventoryTransferDetail = (state, payload) => {
  state.logisticInventoryTransferDetail = payload.root ? convertNullToString(payload.root) : []
}

export const products = (state, payload) => {
  state.products = payload.root ? convertNullToString(payload.root) : []
}

export const productDescriptions = (state, payload) => {
  state.productDescriptions = payload.root ? convertNullToString(payload.root) : []
}

export const productImages = (state, payload) => {
  state.productImages = payload.root ? convertNullToString(payload.root) : []
}

export const productUpsales = (state, payload) => {
  state.productUpsales = payload.root ? convertNullToString(payload.root) : []
}

export const productCrosssales = (state, payload) => {
  state.productCrosssales = payload.root ? convertNullToString(payload.root) : []
}

export const productPrices = (state, payload) => {
  state.productPrices = payload.root ? convertNullToString(payload.root) : []
}



