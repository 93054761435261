export const comboLoading = (state, payload) => {
  state.loading = payload
}

export const allCountries = (state, payload) => {
  state.countries = payload.root ? payload.root : []
}

export const allCities = (state, payload) => {
  state.cities = payload.root ? payload.root : []
}

export const allStates = (state, payload) => {
  state.states = payload.root ? payload.root : []
}

export const allDistrict = (state, payload) => {
  state.districts = payload.root ? payload.root : []
}

export const langs = (state, payload) => {
  state.langs = payload.root ? payload.root : []
}

export const contentTypes = (state, payload) => {
  state.contentTypes = payload.root ? payload.root : []
}

export const contentGroups = (state, payload) => {
  state.contentGroups = payload.root ? payload.root : []
}

export const activeProducts = (state, payload) => {
  state.activeProducts = payload.root ? payload.root : []
}

export const activeProductTypes = (state, payload) => {
  state.activeProductTypes = payload.root ? payload.root : []
}

export const activePos = (state, payload) => {
  state.activePos = payload.root ? payload.root : []
}

export const activeInventoryMoves = (state, payload) => {
  state.activeInventoryMoves = payload.root ? payload.root : []
}

export const activeProductTypesWoS = (state, payload) => {
  state.activeProductTypesWoS = payload.root ? payload.root : []
}

export const activeProductGroups = (state, payload) => {
  state.activeProductGroups = payload.root ? payload.root : []
}

export const activeDesigners = (state, payload) => {
  state.activeDesigners = payload.root ? payload.root : []
}

export const activeCurrencies = (state, payload) => {
  state.activeCurrencies = payload.root ? payload.root : []
}

export const adminUsers = (state, payload) => {
  state.adminUsers = payload.root ? payload.root : []
}

export const shippingCompanyList = (state, payload) => {
  state.shippingCompanyList = payload.root ? payload.root : []
}

export const activeAdCamps = (state, payload) => {
  state.activeAdCamps = payload.root ? payload.root : []
}

export const affiliateUserList = (state, payload) => {
  state.affiliateUserList = payload.root ? payload.root : []
}

export const roleSecurityLevels = (state, payload) => {
  state.roleSecurityLevels = payload.root ? payload.root : []
}

export const careerPositions = (state, payload) => {
  state.careerPositions = payload.root ? payload.root : []
}

export const careerDepartments = (state, payload) => {
  state.careerDepartments = payload.root ? payload.root : []
}

export const adcampTypes = (state, payload) => {
  state.adcampTypes = payload.root ? payload.root : []
}

