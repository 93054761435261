import request from '@/helpers/request'

export const shippingCompanies_list = async ({ commit }, data) => {
  commit('operationLoading', true)
  let res = await request('post', 'distrubitionarea/shippingCompaniesDetail')
  commit('shippingCompany', res.data)
  commit('operationLoading', false)
}

export const shippingCompanies_action = async ({ commit }, data) => {
  commit('operationLoading', true)
  let res = await request('post', 'distrubitionarea/crudShippingCompany', data)
  commit('operationStatus', res.data)
  commit('operationLoading', false)
}

export const countries_list = async ({ commit }, data) => {
  commit('operationLoading', true)
  let res = await request('post', 'distrubitionarea/allCountriesDetail', data)
  commit('countries', res.data)
  commit('operationLoading', false)
}

export const countries_action = async ({ commit }, data) => {
  commit('operationLoading', true)
  let res = await request('post', 'distrubitionarea/updateCountry', data)
  commit('operationStatus', res.data)
  commit('operationLoading', false)
}

export const returnWarehouse_list = async ({ commit }, data) => {
  commit('operationLoading', true)
  let res = await request('post', 'combo/allReturnWarehouseIds', data)
  commit('returnWarehouses', res.data)
  commit('operationLoading', false)
}

export const distributionArea_list = async ({ commit }, data) => {
  commit('operationLoading', true)
  let res = await request('post', 'distrubitionarea/getAllDistrictsByCityIdAndShippingCompany', data)
  commit('distributionAreas', res.data)
  commit('operationLoading', false)
}

export const distributionArea_action = async ({ commit }, data) => {
  commit('operationLoading', true)
  let res = await request('post', 'distrubitionarea/updateDistrictDistributionArea', data)
  commit('operationStatus', res.data)
  commit('operationLoading', false)
}

