import request from '@/helpers/request'

export const moveLog_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', 'inventoryMoveLog/search', data)
  commit('moveLog', res.data)
  commit('inventoryLoading', false)
}

export const moveLog_moveLog = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  data.noToast = true
  let res = await request('post', 'inventoryMoveLog/move', data)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const moveLog_moveLogSpecial = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', 'inventoryMoveLog/moveSpecial', data)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const moveLog_get = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', 'inventoryMoveLog/getMoveLog', {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const inventory_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', 'inventory/search', data)
  commit('inventory', res.data)
  commit('inventoryLoading', false)
}

export const inventory_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', 'inventory/inventoryAddRemove', {params:data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const moveLog_excel = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('get', 'inventoryMoveLog/getexcel', {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const inventory_excel = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('get', 'inventory/getexcel', data)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const inventory_showBarcode = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('get', 'inventory/showSkuBarcode', {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const inventorySummary_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/summary`)
  commit('inventorySummaries', res.data)
  commit('inventoryLoading', false)
}

export const inventory_summaryDetail = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/summarydetail`, {params: data})
  commit('inventorySummaryDetail', res.data)
  commit('inventoryLoading', false)
}

export const inventorySummary_excel = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('get', `inventory/getExcelOfSum`,  data)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const inventoryLog_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventoryLog/search`,   data)
  commit('inventoryLogs', res.data)
  commit('inventoryLoading', false)
}

export const inventoryLog_excel = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('get', `inventoryLog/getexcel`,  data)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const inventoryTracking_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/getInventoryTracking`,  data)
  commit('inventoryTrackings', res.data)
  commit('inventoryLoading', false)
}

export const inventoryTracking_excel = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('get', `inventory/getexcelbyinventorytracking`,  data)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const checkInvoicePrintStatus = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/checkInvoicePrintStatus`)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const inventoryTracking_print = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/activeOrPassiveInvoicePrint`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const logisticInventory_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/logisticInventory`, data)
  commit('logisticInventories', res.data)
  commit('inventoryLoading', false)
}

export const logisticInventory_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/updateLogisticInventoryTransferId`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const logisticInventory_excel = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('get', `inventory/getExcelByLogisticInventory`,  data)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const logisticInventoryTransfer_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/logisticInventoryTransfer`, data)
  commit('logisticInventoryTransfer', res.data)
  commit('inventoryLoading', false)
}

export const logisticInventoryTransfer_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/createLogisticInventoryTransfer`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const logisticInventoryTransfer_control = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/checkLogisticsInventoryTransfers`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const logisticInventoryTransfer_cancel = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/cancelLogisticInventoryTransfer`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const logisticInventoryTransfer_detail = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `inventory/logisticInventoryDetail`, {params: data})
  commit('logisticInventoryTransferDetail', res.data)
  commit('inventoryLoading', false)
}

export const product_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/search`, data)
  commit('products', res.data)
  commit('inventoryLoading', false)
}

export const product_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/productCrud`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const productDescription_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/attrExtSearch`, {params: data})
  commit('productDescriptions', res.data)
  commit('inventoryLoading', false)
}

export const productImage_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/imagesSearch`, {params: data})
  commit('productImages', res.data)
  commit('inventoryLoading', false)
}

export const productUpsale_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/upsaleSearch`, {params: data})
  commit('productUpsales', res.data)
  commit('inventoryLoading', false)
}

export const productCrosssale_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/crosssaleSearch`, {params: data})
  commit('productCrosssales', res.data)
  commit('inventoryLoading', false)
}

export const productPrice_list = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/pricesSearch`, {params: data})
  commit('productPrices', res.data)
  commit('inventoryLoading', false)
}

export const productDescription_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/attrCrud`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const productImage_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/upload`, data)
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const productUpsale_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/upsaleCrud`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const productCrosssale_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/crosssaleCrud`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

export const productPrices_action = async ({ commit }, data) => {
  commit('inventoryLoading', true)
  let res = await request('post', `product/priceCrud`, {params: data})
  commit('inventoryStatus', res.data)
  commit('inventoryLoading', false)
}

