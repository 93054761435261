import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  newsletters: [],
  newsletterPhotos: [],
  newsletterJSP: null,
  newsletterCount: 0,
  iysLog: [],
  iysDetail: [],
  newsletterSubs: [],
  newsletterSubCount: 0,
  iysSubs: [],
  iysSubCount: 0,
  status: {},
})

export default {
  state,
  getters,
  actions,
  mutations
}
