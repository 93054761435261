import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  status: {},
  kaftLovers: [],
  customers: [],
  customerCount: 0,
  kaftLoversCount: 0,
  customerPromecodes: [],
  customerAddresses: [],
  customerAlertLogs: [],
  callEvents: [],
  encrytedEmail: {},
  orderDetailCaller: {}
})

export default {
  state,
  getters,
  actions,
  mutations
}
