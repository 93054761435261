export const adminLoading = state => {
  return state.loading
}

export const adminStatus = state => {
  return state.status
}

export const allPage = state => {
  return state.allPage
}

export const allParentPage = state => {
  return state.allParentPage
}

export const allContent = state => {
  return state.allContent
}

export const contentCount = state => {
  return state.contentCount
}

export const promotions = state => {
  return state.promotions
}

export const promotionRules = state => {
  return state.promotionRules
}

export const promotionAlternative = state => {
  return state.promotionAlternative
}

export const promotionCode = state => {
  return state.promotionCode
}

export const ipWhiteList = state => {
  return state.ipWhiteList;
}
