export const notificationLoading = state => {
  return state.loading
}

export const notificationStatus = state => {
  return state.status
}

export const newsletters = state => {
  return state.newsletters
}

export const newsletterCount = state => {
  return state.newsletterCount
}

export const newsletterPhotos = state => {
  return state.newsletterPhotos
}

export const newsletterJSP = state => {
  return state.newsletterJSP
}

export const iysLog = state => {
  return state.iysLog
}

export const iysDetail = state => {
  return state.iysDetail
}

export const newsletterSubs = state => {
  return state.newsletterSubs
}

export const newsletterSubCount = state => {
  return state.newsletterSubCount
}

export const iysSubs = state => {
  return state.iysSubs
}

export const iysSubCount = state => {
  return state.iysSubCount
}

