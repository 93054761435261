import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  status: {},
  orders: [],
  orderDetail: [],
  productionPackings: [],
  productionPackingItems: [],
  productionCompanies: [],
  productionCompanyOrders: [],
  productionModels: [],
  productionPackingPrint: null,
  productionInvoices: [],
  productionOrderPackings: [],
  productionInvoiceDetails: [],
  productionGenerateItem: [],
  productionOrderCheckList: []
})

export default {
  state,
  getters,
  actions,
  mutations
}
