export const operationLoading = (state, payload) => {
  state.loading = payload
}

export const operationStatus = (state, payload) => {
  state.status = payload
}

export const shippingCompany = (state, payload) => {
  state.shippingCompanies = payload.root ? payload.root : []
  state.count = payload.count
}

export const countries = (state, payload) => {
  state.countries = payload.root ? payload.root : []
  state.count = payload.totalCount
}

export const returnWarehouses = (state, payload) => {
  state.returnWarehouses = payload.root ? payload.root : []
  state.count = payload.totalCount
}

export const distributionAreas = (state, payload) => {
  state.distributionAreas = payload.root ? payload.root : []
  state.count = payload.totalCount
}
