import { convertNullToString } from "@/helpers/custom"
export const productionLoading = (state, payload) => {
  state.loading = payload
}

export const productionStatus = (state, payload) => {
  state.status = payload
}

export const orders = (state, payload) => {
  state.orders = payload.root ? convertNullToString(payload.root) : []
}

export const orderDetail = (state, payload) => {
  state.orderDetail = payload.root ? convertNullToString(payload.root): []
}

export const productionPackings = (state, payload) => {
  state.productionPackings = payload.root ? convertNullToString(payload.root) : []
}

export const productionPackingItems = (state, payload) => {
  if(payload.root){
    payload.root.map(p => {
      p.XS = p.XS ? p.XS : 0;
      p.S = p.S ? p.S : 0;
      p.M = p.M ? p.M : 0;
      p.L = p.L ? p.L : 0;
      p.XL = p.XL ? p.XL : 0;
    })
    state.productionPackingItems = convertNullToString(payload.root)
  }else {
    state.productionPackingItems = []
  }
}

export const productionCompanies = (state, payload) => {
  state.productionCompanies = payload.root ? convertNullToString(payload.root) : []
}

export const productionCompanyOrders = (state, payload) => {
  state.productionCompanyOrders = payload.root ? convertNullToString(payload.root) : []
}

export const productionModels = (state, payload) => {
  state.productionModels = payload.root ? convertNullToString(payload.root) : []
}

export const productionPackingPrint = (state, payload) => {
  state.productionPackingPrint = payload
}

export const productionInvoices = (state, payload) => {
  state.productionInvoices = payload.root ? convertNullToString(payload.root) : []
}

export const productionOrderPackings = (state, payload) => {
  state.productionOrderPackings = payload.root ? convertNullToString(payload.root) : []
}

export const productionInvoiceDetails = (state, payload) => {
  state.productionInvoiceDetails = payload.root ? convertNullToString(payload.root) : []
}

export const productionGenerateItem = (state, payload) => {
  state.productionGenerateItem = payload.root ? convertNullToString(payload.root) : []
}

export const productionOrderCheckList = (state, payload) => {
  state.productionOrderCheckList = payload.root ? convertNullToString(payload.root) : []
}

