import 'core-js'
import Vue from 'vue'
import CoreuiVue from '@coreui/vue-pro/src'
import App from './App'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'
import router from './router'
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'


Vue.use(RouterTab)
Vue.use(CoreuiVue)

Vue.prototype.$log = console.log.bind(console)

Vue.config.productionTip = false

var vm = new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})

global.vm = vm
