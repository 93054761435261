import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  countries: [],
  cities: [],
  langs: [],
  contentTypes: [],
  contentGroups: [],
  activeProducts: [],
  activeProductTypes: [],
  activePos: [],
  activeInventoryMoves: [],
  activeProductTypesWoS: [],
  activeProductGroups: [],
  activeDesigners: [],
  activeCurrencies: [],
  adminUsers: [],
  shippingCompanyList: [],
  states: [],
  districts: [],
  activeAdCamps: [],
  affiliateUserList: [],
  roleSecurityLevels: [],
  careerPositions: [],
  careerDepartments: [],
  adcampTypes: []
})

export default {
  state,
  getters,
  actions,
  mutations
}
