import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  shippingCompanies: [],
  countries: [],
  distributionAreas: [],
  returnWarehouses: [],
  status: {},
  count: 0
})

export default {
  state,
  getters,
  actions,
  mutations
}
