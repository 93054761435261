import { convertNullToString } from "@/helpers/custom"

export const marketingLoading = (state, payload) => {
  state.loading = payload
}

export const marketingStatus = (state, payload) => {
  state.status = payload
}

export const adcamps = (state, payload) => {
  state.adcamps = payload.root ? convertNullToString(payload.root) : []
}

export const adCampReportData = (state, payload) => {
  state.adCampReportData = payload.root ? convertNullToString(payload.root) : []
}