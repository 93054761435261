import { convertNullToString } from "@/helpers/custom"

export const socialLoading = (state, payload) => {
  state.loading = payload
}

export const socialStatus = (state, payload) => {
  state.status = payload ? payload : {}
}

export const kaftLovers = (state, payload) => {
  state.kaftLovers = payload.root ? convertNullToString(payload.root) : []
  state.kaftLoversCount = payload.totalCount ? payload.totalCount : 0
}

export const customers = (state, payload) => {
  state.customers = payload.root ? convertNullToString(payload.root) : []
  state.customerCount = payload.totalCount ? payload.totalCount : 0
}

export const customerPromecodes = (state, payload) => {
  state.customerPromecodes = payload.root ? convertNullToString(payload.root) : []
}

export const customerAddresses = (state, payload) => {
  state.customerAddresses = payload ? convertNullToString([payload]) : []
}

export const customerAlertLogs = (state, payload) => {
  state.customerAlertLogs = payload.root ? convertNullToString(payload.root) : []
}

export const callEvents = (state, payload) => {
  state.callEvents = payload.root ? convertNullToString(payload.root) : []
}

export const encrytedEmail = (state, payload) => {
  state.encrytedEmail = payload
}

export const orderDetailCaller = (state, payload) => {
  state.orderDetailCaller = payload
}


