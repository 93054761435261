import axios from './axios'

export default async function request(method, url, data) {
  let response
  let responseType = data ? data.responseType ? data.responseType : data.params ? data.params.responseType ? data.params.responseType : 'json' : 'json' : 'json'
  await axios({ method, url, params:data && data.params, data: data, responseType:  responseType, withCredentials: true})
    .then(res => {
      response = res
    })
    .catch(err => {
      if (err.response) {
        response = err.response.data
      } else {
        response = {
          data: {
            message: 'Cannot to server',
            status: 'Error'
          }
        }
      }
    })

  return response
}
