/* eslint-disable no-undef */
import axios from 'axios'

let user = JSON.parse(localStorage.getItem('user'))
let noMessages = ['inventoryMoveLog/getMoveLog', 'inventory/checkInvoicePrintStatus', 'promocode/generateAltCode', 'order/encryptemail', 'orderchange/isThereRefund']

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: user && user.token ? { Authorization: 'Bearer ' + user.token, "Content-Type": "application/json; charset=UTF-8" } : undefined,
  withCredentials: true,
})

axiosInstance.interceptors.response.use( function(response) {
    let toast = {}
    if(response.data && response.data.success){
      if(!noMessages.includes(response.config.url)){
        toast.msg = response.data.message;
        toast.header = 'Başarılı';
        toast.color = 'success'
      }
    }else if(response.data && response.data.message){
      toast.msg = response.data.message;
      toast.header = 'Hata';
      toast.color = 'danger'
    }else if(response.data && response.data.errMsg){
      toast.msg = response.data.message;
      toast.header = 'Hata';
      toast.color = 'danger'
    }else if(response.data && response.data.success == false) {
      toast.msg = 'Bir sorun oluştu ve işlem gerçekleşemedi.'
      toast.header = 'Hata';
      toast.color = 'danger'
    }
    if(toast.msg && !noMessages.includes(response.config.url)){
      if(['inventoryMoveLog/move', 'inventoryMoveLog/moveSpecial'].includes(response.config.url)){
        if(response.config.data.get('commitMove') == 'true'){
          vm.$store.commit('showToast', toast)
        }
        return response
      }
      vm.$store.commit('showToast', toast)
    }
    return response
  }, function(error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      //logout
      localStorage.removeItem('user')
      window.location.reload(true)
    } else {
      const toast = {
        msg: error.message,
        header: 'Hata',
        color: 'danger'
      }
      vm.$store.commit('showToast', toast)
    }
    return error.config
  }
)

export default axiosInstance
