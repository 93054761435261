import request from '@/helpers/request'

export const kaftLovers_list = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'photo/search', data)
  commit('kaftLovers', res.data)
  commit('socialLoading', false)
}

export const kaftLover_action = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'photo/crud', {params: data})
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const kaftLover_upload = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'photo/upload', data)
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const kaftLover_requestApprove = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'photo/requestforapprove', {params: data})
  //en sondaki parametre secure code eski projeden alındı.
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const kaftLover_informUser = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'photos/informer/'+data.photoId+'/'+data.ownerEmail+'/'+data.securityCodeParam, data)
  //en sondaki parametre secure code eski projeden alındı.
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const customer_list = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'customer/search', data)
  commit('customers', res.data)
  commit('socialLoading', false)
}

export const customer_action = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'customer/activateCustomerAccount', {params:data})
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const customer_sendMail = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'customer/sendMailToCustomer', {params:data})
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const customerPromecode_list = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'promocode/searchbycustomer', {params: data})
  commit('customerPromecodes', res.data)
  commit('socialLoading', false)
}

export const customerAddress_list = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('get', 'customer/getCustomerAddress', {params: data})
  commit('customerAddresses', res.data ? res.data.address : null)
  commit('socialLoading', false)
}

export const customerAlertLog_list = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'alert/search', data)
  commit('customerAlertLogs', res.data)
  commit('socialLoading', false)
}

export const callEvent_list = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'calleventlog/search', data)
  commit('callEvents', res.data)
  commit('socialLoading', false)
}

export const ordersByCallerId = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'calleventlog/findOrderByCallingUser', data)
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const customersByCallerId = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'calleventlog/findCustomerByCallingUser', data)
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const beginCall = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'calleventlog/beginCall', {params: data})
  commit('socialStatus', res.data)
  commit('socialLoading', false)
}

export const encryptemail = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('post', 'order/encryptemail', {params: data})
  commit('encrytedEmail', res.data)
  commit('socialLoading', false)
}

export const orderDetail = async ({ commit }, data) => {
  commit('socialLoading', true)
  let res = await request('get', 'order/orderDetail', {params: data})
  commit('orderDetailCaller', res.data)
  commit('socialLoading', false)
}

