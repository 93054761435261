import { convertNullToString } from "@/helpers/custom"

export const reportLoading = (state, payload) => {
  state.loading = payload
}

export const reportStatus = (state, payload) => {
  state.status = payload
}

export const designerStatistics = (state, payload) => {

  state.designerStatistics = typeof payload === 'string' ? JSON.parse(JSON.stringify(payload)).root : payload.root ? convertNullToString(payload.root) : []
  
}
 
export const adCamps = (state, payload) => {
  state.adCamps = payload.root ? convertNullToString(payload.root) : []
}

export const affiliates = (state, payload) => {
  state.affiliates = payload.root ? convertNullToString(payload.root) : []
}

export const stockReports = (state, payload) => {
  state.stockReports = payload.root ? convertNullToString(payload.root) : []
}


