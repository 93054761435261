export const reportLoading = state => {
  return state.loading
}

export const reportStatus = state => {
  return state.status
}

export const designerStatistics = state => {
  return state.designerStatistics
}

export const adCamps = state => {
  return state.adCamps
}

export const affiliates = state => {
  return state.affiliates
}

export const stockReports = state => {
  return state.stockReports
}





