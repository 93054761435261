import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  status: {},
  moveLog: [],
  inventory: [],
  inventorySummaries: [],
  inventorySummaryDetail: [],
  inventoryLogs: [],
  inventoryTrackings:[],
  logisticInventories: [],
  logisticInventoryTransfer: [],
  logisticInventoryTransferDetail: [],
  products: [],
  productDescriptions: [],
  productImages: [],
  productUpsales: [],
  productCrosssales: [],
  productPrices: [],
  inventorySummaryDetailCount: 0
})

export default {
  state,
  getters,
  actions,
  mutations
}
