import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  adcamps: [],
  adCampReportData: [],
  status: {},
  count: 0
})

export default {
  state,
  getters,
  actions,
  mutations
}
