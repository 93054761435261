export const careerLoading = state => {
  return state.loading
}

export const careerStatus = state => {
  return state.status
}

export const jobPostings = state => {
  return state.jobPostings
}

export const careerApplicants = state => {
  return state.careerApplicants
}

export const careerStatusTypes = state => {
  return state.careerStatusTypes
}

