import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  contents: [],
  contentMains: [],
  contentImages: [],
  contentPublishTypes: [],
  //contentGroups: [],
  status: {},
  count: 0
})

export default {
  state,
  getters,
  actions,
  mutations
}
