import { convertNullToString } from "@/helpers/custom"

export const careerLoading = (state, payload) => {
  state.loading = payload
}

export const careerStatus = (state, payload) => {
  state.status = payload
}

export const jobPostings = (state, payload) => {
  state.jobPostings = payload.root ? convertNullToString(payload.root) : []
}

export const careerApplicants = (state, payload) => {
  state.careerApplicants = payload.root ? convertNullToString(payload.root) : []
}

export const careerStatusTypes = (state, payload) => {
  state.careerStatusTypes = payload.root ? convertNullToString(payload.root) : []
}

