import { convertNullToString } from "@/helpers/custom"
export const notificationLoading = (state, payload) => {
  state.loading = payload
}

export const notificationStatus = (state, payload) => {
  state.status = payload
}

export const newsletters = (state, payload) => {
  state.newsletters = payload.root ? convertNullToString(payload.root) : []
  state.newsletterCount = payload.totalCount ? payload.totalCount : 0
}

export const newsletterPhotos = (state, payload) => {
  state.newsletterPhotos = payload ? payload : []
}

export const newsletterJSP = (state, payload) => {
  state.newsletterJSP = payload ? payload : null
}

export const iysLog = (state, payload) => {
  if (payload && payload.root) {
    state.iysLog = convertNullToString(payload.root)
  } else {
    state.iysLog = []
  }
  state.iysLogCount = payload && payload.totalCount ? payload.totalCount : 0
}

export const iysDetail = (state, payload) => {
  if(payload){
    state.iysDetail = payload.root ? convertNullToString(payload.root) : []
    state.iysDetailCount = payload.totalCount ? payload.totalCount : 0
  } else {
    state.iysDetail = [];
    state.iysDetailCount = 0;
  }
}


export const newsletterSubs = (state, payload) => {
  state.newsletterSubs = payload.root ? convertNullToString(payload.root) : []
  state.newsletterSubCount = payload.totalCount ? payload.totalCount : 0

}

export const iysSubs = (state, payload) => {
  if (payload && payload.root) {
    state.iysSubs = convertNullToString(payload.root)
  } else {
    state.iysSubs = []
  }
  state.iysSubCount = payload && payload.totalCount ? payload.totalCount : 0
}

  