import request from '@/helpers/request'

export const designerStatistic_list = async ({ commit }, data) => {
  commit('reportLoading', true)
  let res = await request('post', 'report/designer', {params: data})
  commit('designerStatistics', res.data)
  commit('reportLoading', false)
}

export const adCamp_list = async ({ commit }, data) => {
  commit('reportLoading', true)
  let res = await request('post', 'report/adcamp', {params: data})
  commit('adCamps', res.data)
  commit('reportLoading', false)
}

export const affiliate_list = async ({ commit }, data) => {
  commit('reportLoading', true)
  let res = await request('post', 'report/affiliate', {params: data})
  commit('affiliates', res.data)
  commit('reportLoading', false)
}

export const stockStatus_report = async ({ commit }) => {
  commit('reportLoading', true)
  let res = await request('post', 'report/stock-status')
  commit('stockReports', res.data)
  commit('reportLoading', false)
}



