export const operationLoading = state => {
  return state.loading
}

export const shippingCompanies = state => {
  return state.shippingCompanies
}

export const countries = state => {
  return state.countries
}

export const distributionAreas = state => {
  return state.distributionAreas
}

export const returnWarehouses = state => {
  return state.returnWarehouses
}

export const operationStatus = state => {
  return state.status
}