import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  shippingLoading: false,
  vatOrderCount: 0,
  orderList: [],
  orderStatusTypes: [],
  paymentLog: [],
  blackList: [],
  regionShippingCompanies: [],
  orderRefundLog: [],
  orderCheckList: [],
  orderCheckInvoiceIds: [],
  orderCheckAllChecked: [],
  orderAlertLogs: [],
  orderCanceledInvoices: [],
  orderGiftCards: [],
  orderChanges: [],
  orderChangeDetail:[],
  orderReservedTypes: [],
  orderEArchiceInvoices: [],
  orderChangeCartItems: [],
  orderRefunds: [],
  vatOrders: [],
  informedInvoices: [], 
  marketPlaceInvoices: [],
  marketPlaceProviders: [],
  orderCheckItemChecked: {},
  orderWarehouseReturnList: [],
  orderWarehouseReturnCount: [],
  warehouseReturnOrderInfo:{},
  orderJSP: {},
  status: {},
})

export default {
  state,
  getters,
  actions,
  mutations
}
