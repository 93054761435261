import request from '@/helpers/request'

export const role_action = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'role/'+data.action_type, [data])
  commit('adminStatus', res.data)
  commit('adminLoading', false)
}

export const rolePage_action = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'role/pages', {params: data})
  commit('adminStatus', res.data)
  commit('adminLoading', false)
}

export const ipwhite_list = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'ipwhitelist/search', {params: data})
  commit('ipWhiteList', res.data)
  commit('adminLoading', false)
}

export const ipwhite_action = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'ipwhitelist/crud',  data)
  commit('adminStatus', res.data)
  commit('adminLoading', false)
}

export const allPage_list = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'page/search', {params: data})
  commit('allPage', res.data)
  commit('adminLoading', false)
}

export const allParentPage_list = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'page/parentPages', {params: data})
  commit('allParentPage', res.data)
  commit('adminLoading', false)
}

export const page_action = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'page/crud', {params: data})
  commit('adminStatus', res.data)
  commit('adminLoading', false)
}

export const promotion_list = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'promocode/search', data)
  commit('promotions', res.data)
  commit('adminLoading', false)
}

export const promotion_action = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'promocode/crud', {params: data})
  commit('adminStatus', res.data)
  commit('adminLoading', false)
}

export const promotionRules_list = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'promocode/getRules', data)
  commit('promotionRules', res.data)
  commit('adminLoading', false)
}

export const promotionRules_action = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'promocode/crudRule', {params: data})
  commit('adminStatus', res.data)
  commit('adminLoading', false)
}

export const promotionAlternative_list = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'promocode/getAlternatives', data)
  commit('promotionAlternative', res.data)
  commit('adminLoading', false)
}

export const promotionAlternative_action = async ({ commit }, data) => {
  commit('adminLoading', true)
  let res = await request('post', 'promocode/crudAlt', {params: data})
  commit('adminStatus', res.data)
  commit('adminLoading', false)
}
export const promotionAlternative_getCode = async ({ commit }) => {
  commit('adminLoading', true)
  let res = await request('post', 'promocode/generateAltCode', )
  commit('promotionCode', res.data)
  commit('adminLoading', false)
}