import request from '@/helpers/request'

export const jobPosting_list = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/search', data)
  commit('jobPostings', res.data)
  commit('careerLoading', false)
}

export const jobPosting_add = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/add', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const jobPosting_update = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/update', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const jobPosting_changeStatus = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/changeStatus', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const jobPosting_delete = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/delete', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerPosition_add = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/positionAdd', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerPosition_update = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/positionUpdate', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerPosition_delete = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/positionRemove', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerApplicants_list = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/applicantList', data)
  commit('careerApplicants', res.data)
  commit('careerLoading', false)
}

export const careerApplicants_delete = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/removeApplicant', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerApplicants_update = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/updateApplicant', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerApplicants_changeAll = async ({ commit }, data) => {
  commit('careerLoading', true)
  let securityCode = process.env.VUE_APP_SECURITYCODE
  let res = await request('post', 'career/applicantStatus/'+securityCode, data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerStatusType_list = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/allStatusTypes',  data)
  commit('careerStatusTypes', res.data)
  commit('careerLoading', false)
}


export const careerDepartment_add = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/departmentAdd', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerDepartment_update = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/departmentUpdate', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}

export const careerDepartment_delete = async ({ commit }, data) => {
  commit('careerLoading', true)
  let res = await request('post', 'career/departmentRemove', data)
  commit('careerStatus', res.data)
  commit('careerLoading', false)
}