import Vue from 'vue'
import Vuex from 'vuex'
import Operation from './store/operation'
import User from './store/user'
import Combo from './store/combo'
import Admin from './store/admin'
import Notification from './store/notification'
import Production from './store/production'
import Social from './store/social'
import Inventory from './store/inventory'
import Orders from './store/orders'
import Reports from './store/report'
import HR from './store/hr'
import Marketing from './store/marketing'
import Content from './store/content'


Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  fixedToasts: 0,
  toasts: []
}

const mutations = {
  showToast(state, { msg, header, color }) {
    let toast = {}
    toast.msg = msg
    toast.header = header || 'Notification'
    toast.color = color || 'info'
    state.toasts.push(toast)
    state.fixedToasts++
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  toggle(state, variable) {
    state[variable] = !state[variable]
  }
}

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    Operation,
    User,
    Combo,
    Admin,
    Notification,
    Production,
    Social,
    Inventory,
    Orders,
    Reports,
    HR,
    Marketing,
    Content
  }
})
