import { convertNullToString } from "@/helpers/custom"
export const adminLoading = (state, payload) => {
  state.loading = payload
}

export const adminStatus = (state, payload) => {
  state.status = payload
}

export const allPage = (state, payload) => {
  state.allPage = payload.root ? convertNullToString(payload.root) : []
}

export const allParentPage = (state, payload) => {
  state.allParentPage = payload.root ? convertNullToString(payload.root) : []
}

export const allContent = (state, payload) => {
  state.allContent = payload.root ? convertNullToString(payload.root) : []
}

export const promotions = (state, payload) => {
  state.promotions = payload.root ? convertNullToString(payload.root) : []
}

export const promotionRules = (state, payload) => {
  state.promotionRules = payload.root ? convertNullToString(payload.root) : []
}

export const promotionAlternative = (state, payload) => {
  state.promotionAlternative = payload.root ? convertNullToString(payload.root) : []
}

export const promotionCode = (state, payload) => {
  state.promotionCode = payload.message
}

export const ipWhiteList = (state, payload) => {
  state.ipWhiteList = payload.root ? convertNullToString(payload.root) : []
}




