import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  promotionCode: "",
  roles: [],
  allPage: [],
  allParentPage: [],
  allContent: [],
  promotions: [],
  ipWhiteList: [],
  promotionRules: [],
  promotionAlternative: [],
  contentCount: 0,
  status: {},
  count: 0
})

export default {
  state,
  getters,
  actions,
  mutations
}
