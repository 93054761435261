import moment from "moment";

export function convertNullToString(data) {
    data.forEach(function(o) {

        Object.keys(o).forEach(function(key) {
            o[key] === null ? (o[key] = '') : (o[key] = o[key])
        })

    })
    return data;
}

export function prepareFilterParams(params, filters) {
    let formData = undefined
    if(params && Object.keys(params).length > 0){
        formData = new FormData()
        let i = 0;
        let val;

        Object.keys(params).map((p) =>{
            let filterField = filters.find( f => f.dataIndex == p)
            if((filterField.type !== 'boolean' && !params[p]) || params[p] === '')
                return;
            if(filterField.type == 'date'){
                val = moment(params[p]).format('DD/MM/YYYY')
            }else if(filterField.type == 'listString' || filterField.type == 'list'  ){
                if((typeof params[p] == 'Array' || typeof params[p] == 'object') && params[p].length > 0 ){
                    val = params[p].map( function (v) { return v.value})
                  }else if(filterField.type == 'list') {
                    val = params[p]
                  }else 
                    return
            }else{
                val = params[p]
            }

          formData.append("filter" + "[" + i + "][field]", filterField.field);
          formData.append("filter" + "[" + i + "][data][type]", filterField.type);
          formData.append("filter" + "[" + i + "][data][value]", val )
          filterField.comparison && formData.append("filter" + "[" + i + "][data][comparison]", filterField.comparison);

          i++;
        })
    }
   
    return formData;
}


/**
 *  let tmp = []
        if(typeof params[p] == 'Array' || typeof params[p] == 'object' ){
          params[p].map(p =>  tmp.push(p.value))
          value = tmp.join(',')
        }else {
          value =  params[p]
        }
 */