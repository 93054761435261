export const inventoryLoading = state => {
  return state.loading
}

export const inventoryStatus = state => {
  return state.status
}

export const moveLog = state => {
  return state.moveLog
}

export const inventory = state => {
  return state.inventory
}

export const inventorySummaries = state => {
  return state.inventorySummaries
}

export const inventorySummaryDetail = state => {
  return state.inventorySummaryDetail
}

export const inventorySummaryDetailCount = state => {
  return state.inventorySummaryDetailCount
}

export const inventoryLogs = state => {
  return state.inventoryLogs
}

export const inventoryTrackings = state => {
  return state.inventoryTrackings
}

export const logisticInventories = state => {
  return state.logisticInventories
}

export const logisticInventoryTransfer = state => {
  return state.logisticInventoryTransfer
}

export const logisticInventoryTransferDetail = state => {
  return state.logisticInventoryTransferDetail
}

export const products = state => {
  return state.products
}

export const productDescriptions = state => {
  return state.productDescriptions
}

export const productImages = state => {
  return state.productImages
}

export const productUpsales = state => {
  return state.productUpsales
}

export const productCrosssales = state => {
  return state.productCrosssales
}

export const productPrices = state => {
  return state.productPrices
}









