import request from '@/helpers/request'

export const marketingAdCamp_list = async ({ commit }, data) => {
  commit('marketingLoading', true)
  let res = await request('post', 'marketing/adCampSearch', data)
  commit('adcamps', res.data)
  commit('marketingLoading', false)
}

export const marketingAdCamp_action = async ({ commit }, data) => {
  commit('marketingLoading', true)
  let res = await request('post', 'marketing/adcampCrud', data)
  commit('marketingStatus', res.data)
  commit('marketingLoading', false)
}

export const marketingAdCamp_excel = async ({ commit }, data) => {
  commit('marketingLoading', true)
  let res = await request('get', 'marketing/adcampExcelReport', {params: data})
  commit('marketingStatus', res.data)
  commit('marketingLoading', false)
}

export const marketingAdCamp_report = async ({ commit }, data) => {
  commit('marketingLoading', true)
  let res = await request('post', 'marketing/adcampReport', data)
  commit('adCampReportData', res.data)
  commit('marketingLoading', false)
}

export const marketingAdCamp_excelReport = async ({ commit }, data) => {
  commit('marketingLoading', true)
  let res = await request('get', 'marketing/adcampReportExcel', {params: data})
  commit('marketingStatus', res.data)
  commit('marketingLoading', false)
}