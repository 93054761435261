import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  status: {},
  designerStatistics: [],
  adCamps: [],
  affiliates: [],
  stockReports: []
})

export default {
  state,
  getters,
  actions,
  mutations
}
