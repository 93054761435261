import { convertNullToString } from "@/helpers/custom"

export const contentLoading = (state, payload) => {
  state.loading = payload
}

export const contentStatus = (state, payload) => {
  state.status = payload
}

export const contents = (state, payload) => {
  state.contents = payload.root ? convertNullToString(payload.root) : []
}

export const contentImages = (state, payload) => {
  state.contentImages = payload.root ? convertNullToString(payload.root) : []
}

export const contentMains = (state, payload) => {
  state.contentMains = payload.root ? convertNullToString(payload.root) : []
}

export const contentPublishTypes = (state, payload) => {
  state.contentPublishTypes = payload.root ? convertNullToString(payload.root) : []
}


/*export const contentGroups = (state, payload) => {
  state.contentGroups = payload.root ? convertNullToString(payload.root) : []
}*/


