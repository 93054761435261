import { convertNullToString } from "@/helpers/custom"

export const orderLoading = (state, payload) => {
  state.loading = payload
}

export const informShippingLoading = (state, payload) => {
  state.shippingLoading = payload
}

export const orderStatus = (state, payload) => {
  state.status = typeof payload === 'string' ?JSON.parse(JSON.stringify(payload)) : payload
}

export const orderList = (state, payload) => {
  state.orderList = payload.root ? convertNullToString(payload.root) : []
}

export const orderStatusTypes = (state, payload) => {
  state.orderStatusTypes = payload.root ? convertNullToString(payload.root) : []
}

export const orderJSP = (state, payload) => {
  state.orderJSP = payload
}

export const blackList = (state, payload) => {
  state.blackList = payload.root ? convertNullToString(payload.root) : []
}

export const paymentLog = (state, payload) => {
  state.paymentLog = payload.root ? convertNullToString(payload.root) : []
}

export const regionShippingCompanies = (state, payload) => {
  state.regionShippingCompanies = payload.root ? convertNullToString(payload.root) : []
}

export const orderRefundLog = (state, payload) => {
  state.orderRefundLog = payload.root ? convertNullToString(payload.root) : []
}

export const orderCheckList = (state, payload) => {
  state.orderCheckList = payload.root ? convertNullToString(payload.root) : []
  state.orderCheckInvoiceIds = payload.invoiceIds ? payload.invoiceIds : []
  state.orderCheckAllChecked = payload.allChecked
}

export const orderCheckItemChecked = (state, payload) => {
  state.orderCheckItemChecked = payload
}

export const orderCheckListAddChecked = (state, payload) => {
  let orderCheckList = [...state.orderCheckList]
  orderCheckList[payload].check = orderCheckList[payload].check ? orderCheckList[payload].check + 1 : 1
  state.orderCheckList = orderCheckList
}

export const orderAlertLogs = (state, payload) => {
  state.orderAlertLogs = payload.root ? convertNullToString(payload.root) : []
}

export const orderCanceledInvoices = (state, payload) => {
  state.orderCanceledInvoices = payload.root ? convertNullToString(payload.root) : []
}

export const orderGiftCards = (state, payload) => {
  state.orderGiftCards = payload.root ? convertNullToString(payload.root) : []
}

export const orderChanges = (state, payload) => {
  state.orderChanges = payload.root ? convertNullToString(payload.root) : []
}

export const orderChangeDetail = (state, payload) => {
  state.orderChangeDetail = payload.root ? convertNullToString(payload.root) : []
}

export const orderReservedTypes = (state, payload) => {
  state.orderReservedTypes = payload.root ? convertNullToString(payload.root) : []
}

export const orderEArchiceInvoices = (state, payload) => {
  state.orderEArchiceInvoices = payload.root ? convertNullToString(payload.root) : []
}

export const orderChangeCartItems = (state, payload) => {
  state.orderChangeCartItems = payload.root ? convertNullToString(payload.root) : []
}

export const orderChangeAddChecked = (state, payload) => {
  let orderChangeCartItems = [...state.orderChangeCartItems]
  orderChangeCartItems[payload].check = orderChangeCartItems[payload].check ? orderChangeCartItems[payload].check + 1 : 1
  state.orderChangeCartItems = orderChangeCartItems
}

export const orderRefunds = (state, payload) => {
  state.orderRefunds = payload.root ? convertNullToString(payload.root) : []
}

export const informedInvoices = (state, payload) => {
  state.informedInvoices = payload.root ? convertNullToString(payload.root) : []
}

export const vatOrders = (state, payload) => {
  state.vatOrders = payload.root ? convertNullToString(payload.root) : []
  state.vatOrderCount = payload.totalCount ? payload.totalCount : 0
}

export const marketPlaceInvoices = (state, payload) => {
  state.marketPlaceInvoices = payload.root ? convertNullToString(payload.root) : []
}

export const marketPlaceProviders = (state, payload) => {
  state.marketPlaceProviders = payload.root ? convertNullToString(payload.root) : []
}

export const orderWarehouseReturnList = (state, payload) => {
  if(payload.root){
    state.orderWarehouseReturnList = convertNullToString(payload.root)
    state.orderWarehouseReturnCount = payload.totalCount ? payload.totalCount : 0
  }else{
    state.orderWarehouseReturnList = []
    state.orderWarehouseReturnCount = 0
  }
  
}

export const warehouseReturnOrderInfo = (state, payload) => {
  state.warehouseReturnOrderInfo = payload ? payload.items ? payload.items[0] : {} : {}
}

