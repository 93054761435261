import {createRoutes} from '@/router'
import { convertNullToString } from "@/helpers/custom"


export const userLoading = (state, payload) => {
  state.loading = payload
}

export const userMenus = (state, payload) => {
  if(payload.children.length > 0) {
    state.menus = payload.children
    let paths = []
    payload.children.map(menu => {
      menu.children.length > 0 && menu.children.map( child => child.checked && paths.push('/'+child.uniqueid))
    })
    state.paths = paths
    createRoutes(payload.children)
  }
}

export const roleMenus = (state, payload) => {
  if(payload.children.length > 0) {
    state.roleMenus = payload.children
  }
}

export const allRoles = (state, payload) => {
  state.allRoles = payload.root ?  convertNullToString(payload.root): []
}

export const allUsers = (state, payload) => {
  state.allUsers = payload.root ? payload.root : []
}

export const userStatus = (state, payload) => {
  state.status = payload
}

export const userVerifyStatus = (state, payload) => {
  state.verifyStatus = payload
}

export const userNotes = (state, payload) => {
  state.userNotes =  payload.root ? convertNullToString(payload.root) : []
}

