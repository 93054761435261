import request from '@/helpers/request'

export const order_list = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/search',  data)
  commit('orderList', res.data)
  commit('orderLoading', false)
}

export const orderStatusType_list = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/allOrderStatusTypes',  data)
  commit('orderStatusTypes', res.data)
  commit('orderLoading', false)
}

export const order_PreparingList = async ({ commit }) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/preparingList')
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_CheckList = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/checklist', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_updateOrderAsCustomerConnection = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/updateOrderAsCustomerConnection', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_updatePaymentAmount = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/updatePaymentAmount', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_invoiceTimeControl = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/invoiceTimeControl', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_blackListSearch = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/blacklistsearch', { params: data})
  commit('blackList', res.data)
  commit('orderLoading', false)
}

export const order_paymentLogSearch = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/paymentlogsearch', { params: data})
  commit('paymentLog', res.data)
  commit('orderLoading', false)
}

export const order_prepareEIrsaliye = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/prepareEIrsaliye', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_eInvoicePrint = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/eInvoicePrint', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_readyToPrintEInvoice = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/getReadyToPrintEInvoice', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_eInvoicePrintAuto = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/eInvoicePrintAuto', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_saveOrdersGib = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/saveOrdersReportedToGib', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_updateInvoice = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/updateInvoice', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}
  
export const order_printReturnForm = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/returnForm', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_getReturnFormIds = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/getReadyReturnFormIdsToPrint', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_printEtgbForm = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/etgbForm', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_printInvoiceDetail = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/invoice', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_getInvoicePdf = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/getInvoicePdfLink', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_giftPrinted = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/giftPrinted', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_giftPrintData = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', 'order/getReadyGiftToPrint', { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_giftPrintAuto = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/giftPrintedAuto', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_giftCard = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/${data.actionType == 'add' ? 'addGiftCard' : 'editGiftCard'}`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_regionShippingCompanies = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/orderRegionShippingCompanies`, { params: data})
  commit('regionShippingCompanies', res.data)
  commit('orderLoading', false)
}

export const order_cargoInfo = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/shipping`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_changeEmail = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/changeEmailAddress`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_changeAddress = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/changeAddress`, data)
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_changePhone = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/changePhoneNumber`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_informShippingCompany = async ({ commit }, data) => {
  commit('orderLoading', true)
  commit('informShippingLoading', true)
  let res = await request('post',  `order/informShippingCompany`, { params: data})
  commit('orderStatus', res.data)
  commit('informShippingLoading', false)
  commit('orderLoading', false)
}

export const orderchange_informShippingCompany = async ({ commit }, data) => {
  commit('orderLoading', true)
  commit('informShippingLoading', true)
  let res = await request('post',  `orderchange/informShippingCompany`, { params: data})
  commit('orderStatus', res.data)
  commit('informShippingLoading', false)
  commit('orderLoading', false)
}

export const order_shippingAddress = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `order/shippingAddress`, { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_etgbFormExcel = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `order/getExcelByEtgbList`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_companyEInvoice = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateCompanyEInvoice`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_ettn = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateOrAddEttn`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_delivered = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/setOrderDelivered`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_returned = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/setOrderReturned`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_canceled = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/setOrderCanceled`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_addBlackList = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/addBlackList`,  data)
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_getExcel = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `order/getExcelByOrders`, data)
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_refundLogs = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/searchRefundLogs`, { params: data})
  commit('orderRefundLog', res.data)
  commit('orderLoading', false)
}

export const orderCheck_list = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/getCartItems`, { params: data})
  commit('orderCheckList', res.data)
  commit('orderLoading', false)
}

export const orderCheck_isItemChecked = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/isItemsChecked`, { params: data})
  commit('orderCheckItemChecked', res.data)
  commit('orderLoading', false)
}



export const orderChange_isItemChecked = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `orderchange/isItemsChecked`, { params: data})
  commit('orderCheckItemChecked', res.data)
  commit('orderLoading', false)
}

export const orderChange_itemsChecked = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `orderchange/itemsChecked`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const orderCheck_itemsChecked = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/itemsChecked`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_alertLogs = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', `alert/search`, data)
  commit('orderAlertLogs', res.data)
  commit('orderLoading', false)
}

export const order_canceledInvoices = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post', `order/canceledInvoices`, data)
  commit('orderCanceledInvoices', res.data)
  commit('orderLoading', false)
}

export const order_updateCanceledInvoice = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateCanceledInvoiceInform`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_giftCards = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/giftCardOrders`, data)
  commit('orderGiftCards', res.data)
  commit('orderLoading', false)
}

export const order_updateGiftCardLimit = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateGiftCardTotalAndSpentAmount`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_giftCardCrud = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/crudGiftCard`, data)
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_updateGiftCardStatus = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateGiftCardStatus`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const orderChange_list = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `orderchange/search`, data)
  commit('orderChanges', res.data)
  commit('orderLoading', false)
}

export const order_changeDetail = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `orderchange/searchDetail`, {params: data})
  commit('orderChangeDetail', res.data)
  commit('orderLoading', false)
}

export const order_reservedTypes = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `orderchange/orderChangeReservedTypes`, {params: data})
  commit('orderReservedTypes', res.data)
  commit('orderLoading', false)
}

export const orderChange_commit = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `orderchange/commit`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const orderChange_cancel = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `orderchange/cancel`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const orderChange_isThereRefund = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `orderchange/isThereRefund`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_eArchiveInvoices = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/eArchiveInvoices`, data)
  commit('orderEArchiceInvoices', res.data)
  commit('orderLoading', false)
}

export const order_eArchiveExcel = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `order/getExcelByEArchiveInvoices`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_resendInvoices = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/resendInvoices`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_invoiceEttn = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateInvoiceEttn`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const orderChange_cartItems = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `orderchange/getOrderChangeCartItems`, { params: data})
  commit('orderChangeCartItems', res.data)
  commit('orderLoading', false)
}

export const orderCheck_itemChecked = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/itemsChecked`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_refundExcel = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `order/getExcelByRefundOrders`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_refunds = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/refundOrders`, { params: data})
  commit('orderRefunds', res.data)
  commit('orderLoading', false)
}

export const order_informedInvoices = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/informedInvoices`, { params: data})
  commit('informedInvoices', res.data)
  commit('orderLoading', false)
}

export const order_informedInvoicesExcel = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `order/getExcelByInformedInvoices`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_vatOrders = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/vatOrders`, { params: data})
  commit('vatOrders', res.data)
  commit('orderLoading', false)
}

export const order_vatOrdersExcel = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `order/getExcelByVatOrders`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_vatOrderPaidCarrier = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/vatOrderUpdate`, { params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_shippingAwbBarcode = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get', 'order/shipmentAwbBarcodePrint', { params: data})
  commit('orderJSP', res.data)
  commit('orderLoading', false)
}

export const order_marketPlaceInvoices = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/marketplaceInvoices`, { params: data})
  commit('marketPlaceInvoices', res.data)
  commit('orderLoading', false)
}

export const order_marketPlaceInvoicesExcel = async ({ commit }) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/excelExportByMarketplaceInvoices`)
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_marketPlaceProviders = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/marketplaceProviders`, { params: data})
  commit('marketPlaceProviders', res.data)
  commit('orderLoading', false)
}

export const order_importMarketPlaceInvoicesExcel = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/excelImportByMarketplaceInvoices`, data)
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_updateOrderAndInvoiceStatus = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateOrderAndInvoiceStatus`, {params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_updateAsShipped = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateAsShipped`, {params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_giftPrintUpdate = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/updateAsGift`, {params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_warehouseReturn = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/orderWarehouseReturn`,  data)
  commit('orderWarehouseReturnList', res.data)
  commit('orderLoading', false)
}

export const order_getOrderwarehouseReturn = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/getOrderInfoForWarehouseReturn`,  data)
  commit('warehouseReturnOrderInfo', res.data)
  commit('orderLoading', false)
}

export const order_warehouseReturnAdd = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/addOrderWarehouseReturn`, data)
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_warehouseReturnRemove = async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('post',  `order/removeOrderWarehouseReturn`, data)
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}

export const order_getExcelbyOrderWarehouseReturn= async ({ commit }, data) => {
  commit('orderLoading', true)
  let res = await request('get',  `order/getExcelbyOrderWarehouseReturn`, {params: data})
  commit('orderStatus', res.data)
  commit('orderLoading', false)
}