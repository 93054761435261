export const marketingLoading = state => {
  return state.loading
}

export const marketingStatus = state => {
  return state.status
}

export const adcamps = state => {
  return state.adcamps
}

export const adCampReportData = state => {
  return state.adCampReportData
}

