export const productionLoading = state => {
  return state.loading
}

export const productionStatus = state => {
  return state.status
}

export const orders = state => {
  return state.orders
}

export const orderDetail = state => {
  return state.orderDetail
}

export const productionPackings = state => {
  return state.productionPackings
}

export const productionPackingItems = state => {
  return state.productionPackingItems
}

export const productionCompanies = state => {
  return state.productionCompanies
}

export const productionCompanyOrders = state => {
  return state.productionCompanyOrders
}

export const productionModels = state => {
  return state.productionModels
}

export const productionPackingPrint = state => {
  return state.productionPackingPrint
}

export const productionInvoices = state => {
  return state.productionInvoices
}

export const productionOrderPackings = state => {
  return state.productionOrderPackings
}

export const productionInvoiceDetails = state => {
  return state.productionInvoiceDetails
}

export const productionGenerateItem = state => {
  return state.productionGenerateItem
}

export const productionOrderCheckList = state => {
  return state.productionOrderCheckList
}



