import request from '@/helpers/request'

export const userMenus_list = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'page/json/tree', {params: data})
  commit('userMenus', res.data)
  commit('userLoading', false)
}

export const roleMenus_list = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'page/json/tree', {params: data})
  commit('roleMenus', res.data)
  commit('userLoading', false)
}

export const allRole_list = async ({ commit }) => {
  commit('userLoading', true)
  let res = await request('post', 'role/search')
  commit('allRoles', res.data)
  commit('userLoading', false)
}

export const user_action = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'user/crud', {params: data})
  commit('userStatus', res.data)
  commit('userLoading', false)
}

export const allUser_list = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'user/search', {params: data})
  commit('allUsers', res.data)
  commit('userLoading', false)
}

export const userPassword_change = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'user/changePasswordOfUser', {params: data})
  commit('userStatus', res.data)
  commit('userLoading', false)
}

export const auth = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'auth/login', data)
  commit('userStatus', res.data)
  commit('userLoading', false)
}

export const authVerify = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'auth/loginVerify', data)
  commit('userVerifyStatus', res.data)
  commit('userLoading', false)
}

export const user_notes = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'usernote/search?noteOnModel='+data.noteOnModel+'&modelId='+data.modelId, data.formData)
  commit('userNotes', res.data)
  commit('userLoading', false)
}

export const user_noteCrud = async ({ commit }, data) => {
  commit('userLoading', true)
  let res = await request('post', 'usernote/usernoteCrud', data)
  commit('userStatus', res.data)
  commit('userLoading', false)
}




