import request from '@/helpers/request'

export const productionOrder_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionOrders',  data)
  commit('orders', res.data)
  commit('productionLoading', false)
}

export const productionOrderDetail_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionOrdersDetail', {params: data})
  commit('orderDetail', res.data)
  commit('productionLoading', false)
}

export const productionPacking_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionPacking',  data)
  commit('productionPackings', res.data)
  commit('productionLoading', false)
}

export const productionPacking_action = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res;
  if(data.actionType == 'create')
    res = await request('post', 'production/addProductionPacking',  {params: data})
  else 
    res = await request('post', 'production/updateProductionPacking',  {params: data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionPacking_destroy = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/deleteProductionPacking',  {params: data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionPacking_inspection = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/inspectionStatusChange',  {params: data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionPacking_excel = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('get', 'production/getexcelbypackinglist',  {params: data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionPacking_print = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('get', 'production/printProductionPackingList',  {params: data})
  commit('productionPackingPrint', res.data)
  commit('productionLoading', false)
}

export const productionPackingItem_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionPackingList',  {params: data})
  commit('productionPackingItems', res.data)
  commit('productionLoading', false)
}

export const productionPackingItem_action = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/addOrUpdatePackingItem',  {params: data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionPackingItem_destroy = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/removePackingItem',  {params: data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionCompanies_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionCompanies',  {params: data})
  commit('productionCompanies', res.data)
  commit('productionLoading', false)
}

export const productionCompanyOrder_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionOrdersByProductionCompany',  {params: data})
  commit('productionCompanyOrders', res.data)
  commit('productionLoading', false)
}

export const productionOrderPacking_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionPackingByProductionOrder',  {params: data})
  commit('productionOrderPackings', res.data)
  commit('productionLoading', false)
}

export const productionInvoiceDetail_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionInvoiceDetail',  {params: data})
  commit('productionInvoiceDetails', res.data)
  commit('productionLoading', false)
}

export const productionModel_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/allProductsByGender',  {params: data})
  commit('productionModels', res.data)
  commit('productionLoading', false)
}

export const productionInvoice_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionInvoice',   data)
  commit('productionInvoices', res.data)
  commit('productionLoading', false)
}

export const productionInvoice_action = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/addProductionInvoice',  data)
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionInvoiceDetail_action = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/addProductionInvoiceDetail',  {params:data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionInvoiceDetail_remove = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/removeProductionInvoiceDetailItem',  {params:data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionInvoice_remove = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/removeProductionInvoice',  {params:data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionPackingItem_allDestroy = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/removeAllPackingItem',  {params:data})
  commit('productionStatus', res.data)
  commit('productionLoading', false)
}

export const productionPackingItem_generate = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/generatePackingItem',  {params:data})
  commit('productionGenerateItem', res.data)
  commit('productionLoading', false)
}

export const productionOrderCheck_list = async ({ commit }, data) => {
  commit('productionLoading', true)
  let res = await request('post', 'production/productionOrderCheckList', {params: data})
  commit('productionOrderCheckList', res.data)
  commit('productionLoading', false)
}