import request from '@/helpers/request'

export const newsletter_list = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newsletter/search',  data)
  commit('newsletters', res.data)
  commit('notificationLoading', false)
}

export const newsletter_action = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newsletter/crudNewsLetter', data)
  commit('notificationStatus', res.data)
  commit('notificationLoading', false)
}

export const newsletter_status = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newsletter/changeStatus', {params: data})
  commit('notificationStatus', res.data)
  commit('notificationLoading', false)
}

export const newsletterTemplate_photo = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newsletter/uploadTemplateImages',  data)
  commit('notificationStatus', res.data)
  commit('notificationLoading', false)
}

export const newsletterTemplate_file = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newsletter/uploadTemplateFile',  data)
  commit('notificationStatus', res.data)
  commit('notificationLoading', false)
}

export const newsletterTemplate_getPhoto = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newsletter/getTemplateImages',  data)
  commit('newsletterPhotos', res.data)
  commit('notificationLoading', false)
}

export const newsletter_preview = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newsletter/newsletterTemplate', {params: data})
  commit('newsletterJSP', res.data)
  commit('notificationLoading', false)
}

export const newsletterSubs_list = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newslettersubs/search', data)
  commit('newsletterSubs', res.data)
  commit('notificationLoading', false)
}

export const newsletterSubs_action = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'newslettersubs/disabledNewsletterSubsUser',  {params: data})
  commit('notificationStatus', res.data)
  commit('notificationLoading', false)
}

export const iysSubs_list = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'iys/approveSubsList', {params: data})
  commit('iysSubs', res.data)
  commit('notificationLoading', false)
}

export const iysLog_list = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'iys/logList',  data)
  commit('iysLog', res.data)
  commit('notificationLoading', false)
}

export const iysDetail_list = async ({ commit }, data) => {
  commit('notificationLoading', true)
  let res = await request('post', 'iys/detailList',  data)
  commit('iysDetail', res.data)
  commit('notificationLoading', false)
}