export const orderLoading = state => {
  return state.loading
}

export const informShippingLoading = state => {
  return state.shippingLoading
}

export const orderStatus = state => {
  return state.status
}

export const orderList = state => {
  return state.orderList
}

export const orderStatusTypes = state => {
  return state.orderStatusTypes
}

export const orderJSP = state => {
  return state.orderJSP
}

export const blackList = state => {
  return state.blackList
}

export const paymentLog = state => {
  return state.paymentLog
}

export const regionShippingCompanies = state => {
  return state.regionShippingCompanies
}

export const orderRefundLog = state => {
  return state.orderRefundLog
}

export const orderCheckList = state => {
  return state.orderCheckList
}

export const orderCheckInvoiceIds = state => {
  return state.orderCheckInvoiceIds
}

export const orderCheckAllChecked = state => {
  return state.orderCheckAllChecked
}

export const orderCheckItemChecked = state => {
  return state.orderCheckItemChecked
}

export const orderAlertLogs = state => {
  return state.orderAlertLogs
}

export const orderCanceledInvoices = state => {
  return state.orderCanceledInvoices
}

export const orderGiftCards = state => {
  return state.orderGiftCards
}

export const orderChanges = state => {
  return state.orderChanges
}

export const orderChangeDetail = state => {
  return state.orderChangeDetail
}

export const orderReservedTypes = state => {
  return state.orderReservedTypes
}

export const orderEArchiceInvoices = state => {
  return state.orderEArchiceInvoices
}

export const orderChangeCartItems = state => {
  return state.orderChangeCartItems
}

export const orderRefunds = state => {
  return state.orderRefunds
}

export const informedInvoices = state => {
  return state.informedInvoices
}

export const vatOrders = state => {
  return state.vatOrders
}

export const vatOrderCount = state => {
  return state.vatOrderCount
}

export const marketPlaceInvoices = state => {
  return state.marketPlaceInvoices
}

export const marketPlaceProviders = state => {
  return state.marketPlaceProviders
}

export const orderWarehouseReturnList = state => {
  return state.orderWarehouseReturnList
}

export const warehouseReturnOrderInfo = state => {
  return state.warehouseReturnOrderInfo
}

export const orderWarehouseReturnCount = state => {
  return state.orderWarehouseReturnCount
}




