export const socialLoading = state => {
  return state.loading
}

export const socialStatus = state => {
  return state.status
}

export const kaftLovers = state => {
  return state.kaftLovers
}

export const kaftLoversCount = state => {
  return state.kaftLoversCount
}

export const customers = state => {
  return state.customers
}

export const customerCount = state => {
  return state.customerCount
}


export const customerPromecodes = state => {
  return state.customerPromecodes
}

export const customerAddresses = state => {
  return state.customerAddresses
}

export const customerAlertLogs = state => {
  return state.customerAlertLogs
}

export const callEvents = state => {
  return state.callEvents
}

export const encrytedEmail = state => {
  return state.encrytedEmail
}

export const orderDetailCaller = state => {
  return state.orderDetailCaller
}

