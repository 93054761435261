/* eslint-disable no-undef */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { RouterTabRoutes } from 'vue-router-tab'
import Frame from '../components/layout/Frame.vue'
import store from '@/store';

const importPage = (folder, view) => () =>
  import(`../views/${folder}/${view}.vue`)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Frame,
    children: [
      ...RouterTabRoutes,
      {
        path: '/',
        name: 'Home',
        component: importPage('home', 'Home'),
        meta: {
          title: 'Ana Sayfa'
        }
      },
      {
        path: '/auth/login',
        name: 'Login',
        component: importPage('auth', 'Login'),
        meta: {
          title: 'Login'
        }
      },
      {
        path: '/admin/role',
        name: 'Roles',
        component: importPage('admin', 'Roles'),
        meta: {
          title: 'Kullanıcı Rolleri'
        }
      },
      {
        path: '/admin/page',
        name: 'Pages',
        component: importPage('admin', 'Pages'),
        meta: {
          title: 'Ekranlar'
        }
      },
      {
        path: '/admin/user',
        name: 'Users',
        component: importPage('admin', 'Users'),
        meta: {
          title: 'Kullanıcılar'
        }
      },
      {
        path: '/admin/promocodes',
        name: 'Promotions',
        component: importPage('admin', 'Promotions'),
        meta: {
          title: 'Promosyonlar'
        }
      },
      {
        path: '/admin/ipwhitelist',
        name: 'IPWhiteList',
        component: importPage('admin', 'IPWhiteList'),
        meta: {
          title: 'IP Adresi'
        }
      },
      {
        path: '/inventory/inventorymovelog',
        name: 'InventoryMoveLog',
        component: importPage('inventory', 'InventoryMoveLog'),
        meta: {
          title: 'Stok Taşıma'
        }
      },
      {
        path: '/inventory/inventory',
        name: 'Inventory',
        component: importPage('inventory', 'Inventory'),
        meta: {
          title: 'Stok'
        }
      },
      {
        path: '/inventory/inventorysummary',
        name: 'InventorySummary',
        component: importPage('inventory', 'InventorySummary'),
        meta: {
          title: 'Stok Özeti'
        }
      },
      {
        path: '/inventory/product',
        name: 'Products',
        component: importPage('inventory', 'Products'),
        meta: {
          title: 'Ürünler'
        }
      },
      {
        path: '/inventory/inventorylog',
        name: 'InventorySummary',
        component: importPage('inventory', 'InventoryLog'),
        meta: {
          title: 'Stok Log'
        }
      },
      {
        path: '/inventory/tag',
        name: 'InventorySummary',
        component: importPage('inventory', 'Tag'),
        meta: {
          title: 'Ürün Etiketleri'
        }
      },
      {
        path: '/inventory/inventorytracking',
        name: 'InventorySummary',
        component: importPage('inventory', 'InventoryTracking'),
        meta: {
          title: 'Stok Takip'
        }
      },
      {
        path: '/inventory/logisticInventory',
        name: 'InventorySummary',
        component: importPage('inventory', 'LogisticInventory'),
        meta: {
          title: 'Lojistik Stok Ekranı'
        }
      },
      {
        path: '/inventory/logisticInventoryTransfer',
        name: 'InventorySummary',
        component: importPage('inventory', 'LogisticInventoryTransfer'),
        meta: {
          title: 'Lojistik Stok Transfer'
        }
      },
      {
        path: '/order/orders',
        name: 'Orders',
        component: importPage('orders', 'Orders'),
        meta: {
          title: 'Siparişler'
        }
      },
      {
        path: '/order/ordercheck',
        name: 'Orders',
        component: importPage('orders', 'OrderCheck'),
        meta: {
          title: 'Sipariş Paketleme'
        }
      },
      {
        path: '/order/alerts',
        name: 'Orders',
        component: importPage('orders', 'AlertLogs'),
        meta: {
          title: 'Hata Logları'
        }
      },
      {
        path: '/order/orderChange',
        name: 'Orders',
        component: importPage('orders', 'OrderChangeReserved'),
        meta: {
          title: 'Sipariş Değişimleri'
        }
      },
      {
        path: '/order/canceledInvoices',
        name: 'Orders',
        component: importPage('orders', 'CanceledInvoices'),
        meta: {
          title: 'Fatura İptal Bildirimi'
        }
      },
      {
        path: '/order/orderRemoteWarehouseReturn',
        name: 'Orders',
        component: importPage('orders', 'OrderWarehouseReturn'),
        meta: {
          title: 'Order Warehuose Return'
        }
      },
      {
        path: '/order/giftCardOrders',
        name: 'Orders',
        component: importPage('orders', 'GiftCardOrders'),
        meta: {
          title: 'Gift Card Siparişleri'
        }
      },
      {
        path: '/order/eArchiveInvoice',
        name: 'Orders',
        component: importPage('orders', 'EArchiveInvoices'),
        meta: {
          title: 'E-Arşiv Fatura Ekranı'
        }
      },
      {
        path: '/order/orderChangeCheck',
        name: 'Orders',
        component: importPage('orders', 'OrderChangeCheck'),
        meta: {
          title: 'Sipariş Değişim Paketleme'
        }
      },
      {
        path: '/order/refundOrders',
        name: 'Orders',
        component: importPage('orders', 'RefundOrders'),
        meta: {
          title: 'İadeler'
        }
      },
      {
        path: '/order/informedInvoices',
        name: 'Orders',
        component: importPage('orders', 'InformedInvoices'),
        meta: {
          title: 'Kesilen Faturalar'
        }
      },
      {
        path: '/order/shipmentAwbBarcode',
        name: 'Orders',
        component: importPage('orders', 'ShipmentAwbBarcode'),
        meta: {
          title: 'Taşıma Barkodu Yazdır'
        }
      },
      {
        path: '/order/marketplaceInvoices',
        name: 'Orders',
        component: importPage('orders', 'MarketPlaceInvoices'),
        meta: {
          title: 'Marketplace Faturaları'
        }
      },
      {
        path: '/order/vatOrders',
        name: 'Orders',
        component: importPage('orders', 'VatOrders'),
        meta: {
          title: 'IOSS'
        }
      },
      {
        path: '/social/blog',
        name: 'Blog',
        component: importPage('social', 'Blog'),
        meta: {
          title: 'Blog'
        }
      },
      {
        path: '/social/photo',
        name: 'KaftLoverPhotos',
        component: importPage('social', 'KaftLoverPhotos'),
        meta: {
          title: 'KAFTSever Fotoğrafları'
        }
      },
      {
        path: '/social/comments',
        name: 'Comments',
        component: importPage('social', 'Comments'),
        meta: {
          title: 'Sipariş Yorumları'
        }
      },
      {
        path: '/social/customers',
        name: 'Customers',
        component: importPage('social', 'Customers'),
        meta: {
          title: 'Müşteriler'
        }
      },
      {
        path: '/social/callEventLog',
        name: 'CallEventLog',
        component: importPage('social', 'CallEventLog'),
        meta: {
          title: 'Müşteri Arama Kayıtları'
        }
      },
      {
        path: '/report/designers',
        name: 'Designers',
        component: importPage('report', 'Designers'),
        meta: {
          title: 'Tasarımcı İstatistikleri'
        }
      },
      {
        path: '/report/adcamp',
        name: 'AdCamp',
        component: importPage('report', 'AdCamp'),
        meta: {
          title: 'Reklam Durum Raporu'
        }
      },
      {
        path: '/report/affiliate',
        name: 'Affiliate',
        component: importPage('report', 'Affiliate'),
        meta: {
          title: 'KAFT Affiliate Programı'
        }
      },
      {
        path: '/production/productionOrders',
        name: 'ProductionOrders',
        component: importPage('production', 'ProductionOrders'),
        meta: {
          title: 'Sipariş Listesi'
        }
      },
      {
        path: '/production/productionPacking',
        name: 'ProductionPacking',
        component: importPage('production', 'ProductionPacking'),
        meta: {
          title: 'Sipariş Çeki Listesi'
        }
      },
      {
        path: '/production/productionInvoice',
        name: 'ProductionInvoice',
        component: importPage('production', 'ProductionInvoice'),
        meta: {
          title: 'Faturalar'
        }
      },
      {
        path: '/operation/cargo',
        name: 'Cargo',
        component: importPage('operation', 'Cargo'),
        meta: {
          title: 'Kargo Firmaları'
        }
      },
      {
        path: '/operation/distributionareas',
        name: 'DistributionAreas',
        component: importPage('operation', 'DistributionAreas'),
        meta: {
          title: 'Dağıtım Bölgeleri'
        }
      },
      {
        path: '/operation/countries',
        name: 'Countries',
        component: importPage('operation', 'Countries'),
        meta: {
          title: 'Teslimat Ülkeleri'
        }
      },
      {
        path: '/notification/newsletters',
        name: 'NewsLetters',
        component: importPage('notification', 'NewsLetters'),
        meta: {
          title: 'NewsLetters'
        }
      },
      {
        path: '/notification/newsLetterSubs',
        name: 'NewsLetterSubs',
        component: importPage('notification', 'NewsLetterSubs'),
        meta: {
          title: 'Newsletter Aboneleri'
        }
      },
      {
        path: '/notification/iysSubs',
        name: 'IYSSubs',
        component: importPage('notification', 'IYSSubs'),
        meta: {
          title: 'IYS Aboneleri'
        }
      },
      {
        path: '/notification/iysLog',
        name: 'IYSLog',
        component: importPage('notification', 'IYSLog'),
        meta: {
          title: 'IYS Bildirimleri Log'
        }
      },
      {
        path: '/notification/iysDetail',
        name: 'IYSDetail',
        component: importPage('notification', 'IYSDetail'),
        meta: {
          title: 'IYS Bildirimleri Detay'
        }
      },
      {
        path: '/hr/jobPostings',
        name: 'JobPosting',
        component: importPage('hr', 'JobPostings'),
        meta: {
          title: 'İş İlanları'
        }
      },
      {
        path: '/hr/careerApplicants',
        name: 'CareerApplicants',
        component: importPage('hr', 'CareerApplicants'),
        meta: {
          title: 'İş Başvuruları'
        }
      },
      {
        path: '/report/stock',
        name: 'Stock',
        component: importPage('report', 'Stock'),
        meta: {
          title: 'Stok Durum Raporu'
        }
      },
      {
        path: '/marketing/adCamp',
        name: 'CareerApplicants',
        component: importPage('marketing', 'AdCamp'),
        meta: {
          title: 'Reklam Kampanyaları'
        }
      },
      {
        path: '/content/Main',
        name: 'Content',
        component: importPage('content', 'ContentMain'),
        meta: {
          title: 'İçerik Yönetimi'
        }
      },
      {
        path: '/content/contentGroup',
        name: 'ContentGroup',
        component: importPage('content', 'ContentGroup'),
        meta: {
          title: 'İçerik Yönetimi'
        }
      },
    ]
  }
]

export const createRoutes = async function() {
  //Component bilgisi de veritabanından gelince aktif edilecek
  /*let dynamicRoutes = [];
  r.map(child =>{
    child.children.map(item => {
      console.log(item.uniqueid);
      item.uniqueid && dynamicRoutes.push({
        path: '/'+item.uniqueid,
        name: 'Cargo',
        component: importPage('operation', 'Cargo'),
        meta: {
          title: item.text
        }
      })
    })
  })
  let { routes } = vm.$router.options;
  let routeData = routes[0]
  routeData.children = [...dynamicRoutes];
  console.log(dynamicRoutes)
  vm.$router.addRoutes([routeData])
  await router.replace('/')*/

}

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired) {
    if( !loggedIn)
      return next('/auth/login')
  }else{
    return next()
  }
  if(loggedIn && store.state.User.menus.length < 1) {
    await store.dispatch('userMenus_list', {roleId: JSON.parse(loggedIn).loggedUser.role.id})
  }
  if(to.path !== '/') {
  if(!store.state.User.paths.includes(to.path)){
    const toast = {
      msg: 'Bu sayfayı görüntüleme yetkiniz bulunmamaktadır.',
      header: 'Yetkisiz Kullanıcı!',
      color: 'danger'
    }
    vm && vm.$store.commit('showToast', toast)
    return next('/')

  }

}
  next()
})

export default router
