import request from '@/helpers/request'

export const content_main_list = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/getContentMains',  data)
  commit('contentMains', res.data)
  commit('contentLoading', false)
}

export const content_main_action = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/mainCrud',  data)
  commit('contentStatus', res.data)
  commit('contentLoading', false)
}

export const content_list = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/getContents',  {params: data})
  commit('contents', res.data)
  commit('contentLoading', false)
}

export const content_action = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/contentCrud',  data)
  commit('contentStatus', res.data)
  commit('contentLoading', false)
}

export const content_images = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/getContentImages', {params: data})
  commit('contentImages', res.data)
  commit('contentLoading', false)
}

export const content_image_action = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/imageCrud', data)
  commit('contentStatus', res.data)
  commit('contentLoading', false)
}

export const content_image_delete = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/imageDelete', {params: data})
  commit('contentStatus', res.data)
  commit('contentLoading', false)
}

export const content_delete = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/delete', {params: data})
  commit('contentStatus', res.data)
  commit('contentLoading', false)
}

export const content_changeActivity = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/changeActivity', {params: data})
  commit('contentStatus', res.data)
  commit('contentLoading', false)
}

export const contentPublishType_list = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/allContentPublishTypes', {params: data})
  commit('contentPublishTypes', res.data)
  commit('contentLoading', false)
}


/*export const contentGroup_list = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/getContentGroups',  data)
  commit('contentGroups', res.data)
  commit('contentLoading', false)
}

export const contentGroup_action = async ({ commit }, data) => {
  commit('contentLoading', true)
  let res = await request('post', 'content/contentGroupsCrud',  data)
  commit('contentStatus', res.data)
  commit('contentLoading', false)
}*/