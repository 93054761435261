import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = () => ({
  loading: false,
  menus: [],
  allRoles: [],
  roleMenus : [],
  allUsers: [],
  userNotes: [],
  paths: [],
  status: {},
  verifyStatus: {}
})

export default {
  state,
  getters,
  actions,
  mutations
}
