export const contentLoading = state => {
  return state.loading
}

export const contentStatus = state => {
  return state.status
}

export const contents = state => {
  return state.contents
}

export const contentMains = state => {
  return state.contentMains
}

export const contentImages = state => {
  return state.contentImages
}

export const contentPublishTypes = state => {
  return state.contentPublishTypes
}



/*export const contentGroups = state => {
  return state.contentGroups
}*/


