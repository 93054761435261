<template>
  <div
    id="app"
    v-if="$route.path === '/auth/login'"
    style="margin-left: -6%; width: 106%"
  >
    <router-view />
  </div>
  <div v-else id="app">
    <div>

      <CModal
        :show.sync="confirmModal"
        :close-on-backdrop="false"
        :centered="true"
        color="dark"
      >
        <span>{{ confirmModalMessage }}</span>
        <template #header>
          <h6 class="modal-title">{{ confirmModalTitle }}</h6>
        </template>
        <template #footer>
          <CButton @click="submit" color="success">Evet</CButton>
          <CButton @click="confirmModal = false" color="danger">Hayır</CButton>
        </template>
      </CModal>

      <div class="row">

        <div>

          <CSidebar  
            dropdownMode="close"
            unfoldable
            :minimize="!show"
            @update:show="value => (this.show = !value)" 
            :style="{marginLeft: showMobileMenu ? show ? 0 : '-256px' : 'auto'}">

            <CSidebarBrand class="d-md-down-none" to="/">
              <img src="/img/logo-light.svg" style="width: 55px;" />
            </CSidebarBrand>

            <CSidebarNav >

              <CNavItem>
                <CSidebarNavItem to="/" icon="cilHome" name="ANASAYFA" :class="{active: checkActive('')}"/>
                
              </CNavItem>

              <CNavItem v-for="parent in items" :key="parent.text" v-if="parent.children.length > 0 && parent.children.some(c => c.checked == true)">
                <CSidebarNavDropdown
                  v-if="parent.children.length > 0 && parent.children.some(c => c.checked == true)"
                  :name="parent.text"
                  :icon="parent.pageIcon"
                  :class="{active: checkActive(parent.children[0].uniqueid.split('/')[0])}"
                >

                  <CNavItem 
                    v-for="item in parent.children" 
                    :key="item.text" 
                    :class="{active: checkActive(item.uniqueid.split('/')[1], 2)}"
                    v-if="item.checked">
                    <CSidebarNavItem :to="'/'+item.uniqueid" :icon="item.pageIcon" :name="item.text" />
                  </CNavItem>

                </CSidebarNavDropdown>

                <router-link v-else-if="parent.checked" to="/">  {{ parent.text }}</router-link>

              </CNavItem>

            </CSidebarNav>

            <hr style="border-top: 1px solid white;" />

            <CButton @click="logout" color="danger" aria-pressed="true" >
              Çıkış yap
            </CButton>

          </CSidebar>

        </div>

        <CToggler
          class="ml-3 customToggle transition"
          :style="{ left: show ? '240px' : showMobileMenu ? '-5px' : '40px', color:'#46c6a3' }"
          @click="changeShow"
          tag="a"
        >

          <CIcon v-if="show" name="cilChevronLeft" size="lg" style="margin: .5rem" />
          <CIcon v-else name="cilChevronRight" size="lg"  style="margin: .5rem" />

        </CToggler>

      </div>

      <div 
        :style="{ 'margin-left': show ? showMobileMenu ? '20px' : '270px' : showMobileMenu ? '0px' : '70px' }" 
        class="transition"
        @click="()=>showMobileMenu && show && changeShow() ">

        <div style="margin-left: 1rem;">
          <router-view />
        </div>

      </div>

    </div>

    <CToaster :autohide="3000">

      <div v-for="(toast, index) in fixedToasts" :key="index">

        <CToast
          :key="'toast' + toast"
          :color="toastColor(index)"
          :show="true"
          :header="toastHeader(index)"
          >
            <p v-html="toastMessage(index)" />
          </CToast>
        
      </div>

    </CToaster>
  </div>
</template>
<script>
export default {
  created: function(){
    window.addEventListener("resize", this.resizeWindow);
  },
  data() {
    return {
      collapse: 0,
      showCollapse: false,
      confirmModal: false,
      confirmModalMessage: '',
      confirmModalTitle: '',
      showMobileMenu: window.innerWidth < 900,
      show: true,
    }
  },
  computed: {
    fixedToasts() {
      return this.$store.state.fixedToasts
    },
    items() {
      return this.$store.state.User.menus
    },
  },
  methods: {
    resizeWindow(){
      this.showMobileMenu = window.innerWidth < 900
    },
    checkActive(data, type){
      return this.$route.path.split("/")[ type ? type : 1] === data
    },
    changeShow() {
      this.show = !this.show
    },
    toastColor(toast) {
      return this.$store.state.toasts[toast].color
    },
    toastHeader(toast) {
      return this.$store.state.toasts[toast].header
    },
    toastMessage(toast) {
      return this.$store.state.toasts[toast].msg
    },
    openMenu(index) {
      this.collapse = index
      this.showCollapse = !this.showCollapse
    },
    async logout() {
      this.confirmModal = true
      this.confirmModalTitle = 'Onay'
      this.confirmModalMessage = 'Çıkış yapmak istediğinizden emin misiniz?'
    },
    async submit() {
      await localStorage.removeItem('user')
      window.location.reload()
    },
  }
}
</script>

<style lang="scss">
@import 'assets/scss/style';
.active > a{
  background-color: #46c6a3 !important;
}
.nav-item.active > a{
  font-weight: bold !important;
  background: rgba(255, 255, 255, 0.05) !important;
  a{
  text-decoration: underline !important;
  background: rgba(255, 255, 255, 0.05) !important;

  }
}

.sidebarMenuLink {
  color: rgba(255, 255, 255, 0.8);
  background: transparent;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.8445rem 1rem;
  text-decoration: none;
  white-space: nowrap;
  transition: background 0.3s, color 0.3s;
}
.nav-link {
  a:hover {
    color: #fff;
  }
}
.transition {
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s,
    margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s;
}
.customToggle {
  border: 0;
  position: absolute;
}
html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.router-tab {
  flex: 1 1 0px;
  overflow-y: scroll;
  height: 100vh;

  &-page {
    padding: 20px 15px;
  }
}

</style>
